<template>
  <div class="bg-white p-3 rounded shadow-sm">
    <h1>Tambah Data Dropping Darah</h1>
    <section class="p-3">
      <b-form-group label="Rumah Sakit Tujuan">
        <v-select
          v-model="formData.rumahSakit"
          :options="rumahSakitOptions"
          label="name"
          placeholder="Pilih Rumah Sakit"
          class="w-100"
          @input="handleInput"
        />
        <p class="mt-2 mb-0 hp-p2-body text-italic text-muted">
          Jika rumah sakit tidak ada maka silakan tambahkan
          <router-link to="/dashboards/add-rs">
            disini
          </router-link>
        </p>
      </b-form-group>
      <div
        class="d-flex justify-content-between"
        style="gap: 1rem"
      >
        <b-form-group
          label="Tanggal Pengiriman"
          class="w-50"
        >
          <b-form-input
            v-model="formData.date"
            type="date"
            placeholder="Tanggal Pengiriman"
          />
        </b-form-group>
        <b-form-group
          label="Jumlah Ice Pack"
          class="w-50"
        >
          <b-form-input
            v-model="formData.icePack"
            type="number"
            placeholder="Jumlah Ice Pack"
          />
        </b-form-group>
      </div>
      <b-form-group label="Barcode">
        <b-form-input
          v-model="formData.barcode"
          type="text"
          placeholder="Barcode"
          @input="debouncedCheckingBarcodes"
        />
      </b-form-group>
      <b-table
        responsive
        show-empty
        :items="tableForm.items"
        :fields="tableForm.fields"
      >
        <template #cell(action)="{ item, index }">
          <b-button
            variant="danger"
            @click="tableForm.items.splice(index, 1)"
          ><i
            class="ri-delete-bin-6-line"
          /></b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <base-button
          variant="primary"
          text="Simpan"
          :disabled="!isValidForm"
          @click="handleSubmit"
        />
      </div>
    </section>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import manageDroppingAPI from '../../../../api/dropping/manageDroppingAPI'
import manageHospitalAPI from '../../../../api/managehospital/manageHospitalAPI'

export default {
  name: 'BloodbagDroppingForm',
  components: {
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    vSelect,
  },
  data() {
    return {
      rumahSakitOptions: [],
      selectedOptions: null,
      formData: {
        rumahSakit: '',
        date: '',
        icePack: 0,
        barcode: '',
      },
      tableForm: {
        items: [],
        fields: [
          {
            key: 'component_barcode',
            label: 'Barcode',
          },
          {
            key: 'blood_type_result',
            label: 'Gol. Darah',
          },
          {
            key: 'volume',
            label: 'Volume',
          },
          {
            key: 'aftap_date',
            label: 'Tgl Pengambilan',
          },
          {
            key: 'prod_date',
            label: 'Tgl Produksi',
          },
          {
            key: 'action',
            label: 'Aksi',
          },
        ],
      },
      debouncedCheckingBarcodes: null,
    }
  },
  computed: {
    isValidForm() {
      return (
        this.formData.rumahSakit
        && this.formData.date
        && this.tableForm.items.length > 0
      )
    },
  },
  created() {
    this.debouncedCheckingBarcodes = debounce(this.checkingBarcodes, 1000)

    this.fetchData()
  },
  methods: {
    async fetchData() {
      const response = await manageHospitalAPI.listDroppingRS({ per_page: 100 })
      const { data } = response.data.data

      this.rumahSakitOptions = data
    },
    async checkingBarcodes() {
      try {
        const { data: response } = await manageDroppingAPI.checkingBarcode(
          this.formData.barcode.trim(),
        )

        if (response && response.data) {
          const existingItem = this.tableForm.items.find(
            item => item.component_barcode === response.data.component_barcode,
          )
          if (!existingItem) {
            this.tableForm.items.push({
              ...response.data,
              volume: Math.round(response.data.volume),
            })
          } else {
            this.$bvToast.toast('Barcode sudah ada di dalam daftar', {
              title: 'Cek barcode',
              variant: 'warning',
              solid: true,
            })
          }
        }
      } catch (error) {
        this.$bvToast.toast('Barcode tidak ditemukan', {
          title: 'Gagal Cek Barcode',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.formData.barcode = ''
      }
    },
    async handleSubmit() {
      try {
        await manageDroppingAPI.add({
          hospital_blood_receivers_id: this.selectedOptions,
          date: this.formData.date,
          ice_pack: this.formData.icePack,
          barcode: this.tableForm.items.map(item => item.component_barcode),
        })

        this.$bvToast.toast('Data berhasil ditambahkan', {
          title: 'Berhasil',
          variant: 'success',
          solid: true,
        })

        this.$router.push({ name: 'dropping' })
      } catch (error) {
        console.error('Error Add Blood Shipment : ', error)
        this.$bvToast.toast(
          error.response?.data?.message || 'Terjadi kesalahan',
          {
            title: 'Gagal Menambahkan Data',
            variant: 'danger',
            solid: true,
          },
        )
      }
    },
    handleInput(selectedValue) {
      this.selectedOptions = selectedValue.id

      console.log("Info Selected :", this.selectedOptions)
    },
  },
}
</script>
